



 import {
  FETCH_SLIPS,
  GET_SLIP,
  ADD_SLIPS,
  UPDATE_SLIPS,
  REMOVE_SLIPS,

} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)


export default {

  async applyGetAllSlips ({ commit }, data) {
     const items = await $service.list(data)
    commit(FETCH_SLIPS, items.data)
  },
  async applyGetSlip ({commit}, data) {
     const item = await $service.item(data)
    commit(GET_SLIP, item.data)
  },
  
}

 