import { mixin } from "@/mixins/mixin";

export default {
  allUsers: (state) => {
    if (state.users.list.length > 0) {
      let { list, total } = state.users

      let listSort = []
      list.forEach(element => {
        listSort.push({...element.person,identity:element.person.lastname + ' ' + element.person.firstname, ...element})
      });
      list = mixin.methods.filterJsonTableByLevel(listSort, "person", "lastname", "");

      return { list, total }
    } else {
      return state.users
    }
  },
}
