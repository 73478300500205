export default {
    userTypes: {
        'broker_inspector': 'Inspecteur',
        'broker_leader': "Chef d'équipe",
        'broker_merchant': "Commercial",
        'broker_general_agent': "Agent général",
        'broker': "Courtier",
        'none': "Aucun",
    },
    allVariableList: [
        { id: "{{Nom}}", label: "Nom" },
        { id: "{{Prénom}}", label: "Prénom" },
        { id: "{{Civilité}}", label: "Civilité" },
        { id: "{{Date_Souscription}}", label: "Date Souscription" },
        { id: "{{Date_Effet}}", label: "Date Effet" },
        { id: "{{Date_Echéance}}", label: "Date Echéance" },
        { id: "{{Produit}}", label: "Produit" },
        { id: "{{Ref_Projet}}", label: "Référence Projet" },
        { id: "{{Ref_Contrat}}", label: "Référence contrat" },
        { id: "{{Nom_Intermédiaire}}", label: "Nom Intermédiaire" },
        { id: "{{Prénom_Intermédiaire}}", label: "Prénom Intermédiaire" },
        { id: "{{Société_Intermédiaire}}", label: "Société Intermédiaire" },
    ]
}