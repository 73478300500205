import {
  FETCH_PROJET_CONTRAT,
  GET_PROJET_CONTRAT,
  FETCH_SITUATION,
  UPDATE_SITUATION
  
} from './mutation-types'

import Vue  from 'vue'

export default {
  [FETCH_SITUATION](state, data) {
    state.situations = data
  },
  [FETCH_PROJET_CONTRAT](state, data) {
    state.projetContrat = data
  },
  [GET_PROJET_CONTRAT](state, projetContrat) {
    state.crrProjetContrat = projetContrat
  },
  [UPDATE_SITUATION](state, situation) {
    const index = state.situations.list.findIndex((el)=>el.id === situation.id)
    state.crrSituation = situation
    Vue.set(state.situations.list, index, situation)
  },
}
