import { VERIFIED, USER, AUTH_TOKEN, AUTHENTICATED } from "./mutation-types";

import { USER_KEY, AUTH_TOKEN_KEY } from "@/constants";

import service from "@/service";
import config from "@/config";
import axios from "axios";

const $service = service(axios, config);

export default {

  async loginAccount ({commit}, userData) {
    let usersType = ["broker_inspector", "broker_leader", "broker_general_agent", "broker", "broker_merchant"]

    return await $service.signin(userData)
      .then((res) => {
        let token = res.data.token
        let user = res.data
        delete user.token
        if(token && user) {
          if(usersType.indexOf(user.type) >= 0) {
            commit(USER, user)
            commit(AUTH_TOKEN, token)
            commit(AUTHENTICATED, true)
            sessionStorage.setItem(USER_KEY, JSON.stringify(user))
          } else {
            return "typeError"
          }
        }
      })
  },
  verified({ commit }) {
    commit(VERIFIED);
  },
  saveUserInfos({ commit }, user) {
    if(user) {
      commit(USER, user)
      sessionStorage.setItem(USER_KEY, JSON.stringify(user))
    }
  },
  async forgetPwd ({commit}, userData) {
    return await $service.forgetPassword(userData)
  },
  async changePwd ({commit}, userData) {
    return await $service.changePassword(userData)
  },
  async applyPutUser ({ commit }, data) {
    return await $service.putUser(data)
  },
  logout({ commit }) {
    commit(USER, null);
    commit(AUTH_TOKEN, null);
    commit(AUTHENTICATED, false);
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
    sessionStorage.removeItem(USER_KEY);
    //delete axios.defaults.headers.common["Access-Token"];
    delete axios.defaults.headers.common["Authorization"];
  }
};
