import {
  UPDATE_PROJECT,
  REMOVE_PROJECT,
  FETCH_PROJECT,
  GET_PROJECT,
  ENABLE_PROJECT,
  ADD_PROJECT_AVENANT,
  UPDATE_PROJECT_AVENANT,
  REMOVE_PROJECT_AVENANT,
  ADD_PROJECT_Quittance,
} from './mutation-types'

import Vue  from 'vue'

export default {

  [FETCH_PROJECT](state, project) {
    state.projects = project
  },

  [ADD_PROJECT_Quittance](state, project) {
    const index = state.projects.list.findIndex((el) => el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)
  },

  [GET_PROJECT](state, data) {
    state.currentProject = data
  },

  [UPDATE_PROJECT](state, project) {
    const index = state.projects.list.findIndex((el)=>el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)
  },

  [ENABLE_PROJECT](state, project) {
    const index = state.projects.list.findIndex((el)=>el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)
    state.projects.total -= 1;
    state.projects.list.splice(index, 1);
  },

  [ADD_PROJECT_AVENANT](state, project) {
    const index = state.projects.list.findIndex((el)=>el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)
  },

  [REMOVE_PROJECT](state, id) {
    const index = state.projects.list.findIndex((el)=>el.id === id)
    state.projects.list.splice(index, 1);
  },

  [REMOVE_PROJECT_AVENANT](state, id) {
    const index = state.currentProject.avenants.findIndex((el)=>el.id === id)
    state.currentProject.avenants.splice(index, 1);
    if (state.currentProject.avenants == 0) {
      const idxProj = state.projects.list.findIndex((el)=>el.id === id)
      state.projects.list.splice(idxProj, 1);
      state.projects.total -= 1;
    }
  },
}
