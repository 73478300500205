import { mixin } from "@/mixins/mixin";

export default {
  allProjetContrats: (state) => {
    return state.projetContrat
  },
  crrProjetContrat: (state) => {
    if (state.crrProjetContrat.quittances && state.crrProjetContrat.quittances.length > 0) {
      let { quittances } = state.crrProjetContrat

      let listSort = []
      quittances.forEach(element => {
        listSort.push({...element})
      });
      quittances = mixin.methods.filterJson(listSort, "createdAt");

      return { ...state.crrProjetContrat, quittances: quittances }
    } else {
      return state.crrProjetContrat
    }
  },
  allSituations: (state) => {
    
    if (state.situations.list.length > 0) {
      let { list, total } = state.situations

      let listSort = []
      list.forEach(element => {
        if(element.status == 'balance')
       {
          let i =  element.crrContribution + element.previousCumulative;
        listSort.push({Cumulated:i })
      }else{
        return element.Cumulated;
      }
      });

      return { list, total }
    } else {
      return state.situation
    }




  },
}
