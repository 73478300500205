import { mixin } from "@/mixins/mixin";

export default {
  allProspectClients: (state) => {
    return state.prospectClient
  },
  crrProspectClient: (state) => {
    console.log(state.crrProspectClient)
    return state.crrProspectClient
  },
}
