// import { mixin } from "@/mixins/mixin";

import { mixin } from "@/mixins/mixin";

export default {
  allProjects: (state) => {
    // return state.projects
    
    if (state.projects.list.length > 0) {
      let { list, total } = state.projects

      let listSort = []
      list.forEach(element => {
        listSort.push({...element, identity:element.quote.firstname + ' ' + element.quote.lastname, email: element.quote.email ,phone: element.quote.phone, startDateContributions: element.quote.startDateContributions})
      });
      //list = mixin.methods.filterJsonTableByLevel(listSort, "quote", "firstname", "");

      return { list: listSort, total }
    } else {
      return state.projects
    }
  },
  currSuscribeData: (state) => {
    return state.currentProject
  },
}
