/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { AUTH_TOKEN_KEY } from "@/constants";

export default ($http, $config) => {
  const $api = $http.create({
    baseURL: $config.serverURL,
    headers: { "Content-Type": "application/json" }
  });

  $api.interceptors.request.use(
    config => {
      const authToken = sessionStorage.getItem(AUTH_TOKEN_KEY);
      if (authToken) {
        //config.headers["Access-Token"] = sessionStorage.getItem(AUTH_TOKEN_KEY);
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  )

  /**
   * Upload File
   * @param {*} data
   */
  const uploadFile = data => {
    return $api.post("/api/v1/upload-file", data).then(res => res.data);
  };

  /**
   * Login user
   * @param {*} data
   */
  const signin = data => {
    return $api.post('/api/v1/auth/signin', data)
      .then(res => res.data)
  }

  /**
   * Forget password
   * @param {*} data
   */
  const forgetPassword = data => {
    return $api.post('/api/v1/auth/forget-pwd', data)
      .then(res => res.data)
  }

  /**
   * Change password
   * @param {*} data
   */
  const changePassword = data => {
    return $api.post('/api/v1/auth/change-pwd', data)
      .then(res => res.data)
  }

  /**
   * Update user
   * @param {*} data
   */
  const putUser = data => {
    return $api.post('/api/v1/users/put', data)
      .then(res => res.data)
  }

/**
   * Add setting
   * @param {*} data
   */
  const addSetting = data => {
    return $api.post('/api/v1/setting/add', data)
      .then(res => res.data)
  }

  /**
   * Update setting
   * @param {*} data
   */
  const putSetting = data => {
    return $api.post('/api/v1/setting/put', data)
      .then(res => res.data)
  }

  /**
   * Remove setting
   * @param {*} data
   */
  const removeSetting = data => {
    return $api.post('/api/v1/setting/remove', data)
      .then(res => res.data)
  }

  /**
   * setting
   * @param {*} data
   */
  const getSetting = (params) => {
    return $api
      .get('/api/v1/setting/item', {
        params
      })
      .then(res => res.data);
  }

   /**
   *  parrainStats
   * @param {userId, parentCode} data
   */
  const parrainStats = data => {
    return $api.post("/api/v1/users/parrain/stats", data).then(res => res.data);
  };

/**
 *
 * Get All slips
 */
const list = params => {
  return $api
    .get("/api/v1/bordereau/list", {
      params
    }).then(res => res.data);
};

/**
 * @param{PARAMS(bordereauId) }
 * Get slip
 */

const item = params => {
  return $api
    .get("/api/v1/bordereau/item", {
      params
    })
    .then(res => res.data);
};

  /**
   * Allow (Active/Inactive) user
   * @param {*} data
   */
  const allowUser = data => {
    return $api.post('/api/v1/users/allow', data)
      .then(res => res.data)
  }

  /**
   * Init password user
   * @param {*} data
   */
  const initializeUser = data => {
    return $api.post('/api/v1/users/initialize', data)
      .then(res => res.data)
  }

  /**
   * Add user
   * @param {*} data
   */
  const addUser = data => {
    return $api.post('/api/v1/users/add', data)
      .then(res => res.data)
  }


  /**
   * Get User
   * @param {*} data
   */
  const getUser = (params) => {
    return $api
      .get('/api/v1/users/item', {
        params
      })
      .then(res => res.data);
  }
   /**
   * Update user
   * @param {*} data
   */
  const putUsers = data => {
    return $api.post('/api/v1/users/put', data)
      .then(res => res.data)
  }

  /**
   * Get All Users
   * @param {*} data
   */
  const getAllUsers = (params) => {
    return $api
      .get("/api/v1/users/list", {
        params
      })
      .then(res => res.data);
  }

  /**
   * Remove user
   * @param {*} data
   */
  const removeUser = data => {
    return $api.post('/api/v1/users/remove', data)
      .then(res => res.data)
  }



  /**
   * On Going Project
   */


  const addCommentProject = data => {
    return $api.post('/api/v1/project/comment/add', data)
      .then(res => res.data)
  };
  const putCommentProject = data => {
    return $api.post('/api/v1/project/comment/put', data)
      .then(res => res.data)
  };


   /**
   * remove comment
   * @param {*} data
   */
  const removeComment = data => {
    return $api.post('/api/v1/ticket/comment/remove', data)
      .then(res => res.data)
  }


   /**
   * Update project
   * @param {*} data
   */
  // const putProject = data => {
  //   return $api.post('/api/v1/project/put', data)
  //     .then(res => res.data)
  // };


    /**
   * allSuscribe
   * @param {*} data
   */
  const getAllProject = (params) => {
    return $api
      .get('/api/v1/project/list', {
        params
      })
      .then(res => res.data);
  };


 /**
 * Suscribe
 * @param {*} data
 */
  const getProject = (params) => {
    return $api
      .get('/api/v1/project/item', {
        params
      })
      .then(res => res.data);
  };

  const putProject = data => {
    return $api.post('/api/v1/project/put', data)
      .then(res => res.data)
  };

  const addAvenant = data => {
    return $api.post('/api/v1/project/avenant/add', data)
      .then(res => res.data)
  };

  const putAvenant = data => {
    return $api.post('/api/v1/project/avenant/put', data)
      .then(res => res.data)
  };

  const removeAvenant = data => {
    return $api.post('/api/v1/project/avenant/remove', data)
      .then(res => res.data)
  };


   /**
   * remove project
   * @param {*} data
   */
  const removeProject = data => {
    return $api.post('/api/v1/project/remove', data)
      .then(res => res.data)
  };

    /**
   * Change project State
   * @param {*} data
   */
  const changeProjectState = data => {
    return $api.post('/api/v1/project/allow', data)
      .then(res => res.data)
  };

  /**
   * SEND MAIL
   * @param {*} data
   */
  const sentProjectMail = data => {
    return $api.post('/api/v1/project/send/mail', data)
      .then(res => res.data)
  };



  const addSimulation = data => {
    return $api.post("/api/v1/project/simulation", data).then(res => res.data)
  };



  const getAllReceipt = (params) => {
    return $api
      .get('/api/v1/project/quittance/list', {
        params
      })
      .then(res => res.data);
  };

  const generateCotisation = data => {
    return $api.post('/api/v1/pdf/generate/no-save/cotisation', data)
      .then(res => res.data)
  }

  const generateCotisationSolde = data => {
    return $api.post('/api/v1/pdf/generate/no-save/cotisation/solde', data)
      .then(res => res.data)
  }

  const getItemDocument = (params) => {
    return $api.get('/api/v1/doc-client/item', { params }).then(res => res.data);
  }

  const putQuittance = data => {
    return $api.post('/api/v1/project/quittance/put', data)
      .then(res => res.data)
  }

  const changeQuittanceState = data => {
    return $api.post('/api/v1/project/quittance/allow', data)
      .then(res => res.data)
  };



  return {

    addCommentProject,putCommentProject,removeComment,
    getAllProject,getProject,putProject,addAvenant,
    putAvenant,removeAvenant,removeProject,changeProjectState,sentProjectMail,addSimulation,

    uploadFile,
    signin,
    forgetPassword,
    changePassword,
    putUser,
    addSetting, putSetting, removeSetting, getSetting,
    parrainStats,list,item,allowUser,initializeUser,addUser,getUser,removeUser,putUsers,getAllUsers,getAllReceipt,
    generateCotisation, generateCotisationSolde, getItemDocument,
    changeQuittanceState, putQuittance,
  }
}
