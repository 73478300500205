import {
  FETCH_SLIPS,
  GET_SLIP,
} from './mutation-types'

import Vue  from 'vue'

export default {
  [GET_SLIP](state, data) {
    state.currentSlip = data
  },
  [FETCH_SLIPS](state, data) {
    state.slips = data
  },
 
}
