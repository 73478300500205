import {
  FETCH_PROSPECT_CLIENT,
  GET_PROSPECT_CLIENT
} from './mutation-types'

import Vue  from 'vue'

export default {
  [FETCH_PROSPECT_CLIENT](state, data) {
    state.prospectClient = data
  },
  [GET_PROSPECT_CLIENT](state, prospectClient) {
    state.crrProspectClient = prospectClient
  }
}
