import Vue from 'vue'
import Router from 'vue-router'
import authGuard from './auth-guard'

Vue.use(Router)

const router = new Router({
	base: "/",
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			// =============================================================================
			// MAIN LAYOUT ROUTES
			// =============================================================================
			path: '',
			component: () => import('@/layouts/main/Main.vue'),
			children: [
				// =============================================================================
				// Theme Routes
				// =============================================================================
				{
					path: '/',
					name: 'Home',
					component: () => import('@/views/Home.vue'),
					meta: {
						auth: true,
						breadcrumb: [
							{ title: 'Accueil', active: true },
						],
						pageTitle: 'Accueil'
					},
				},
				{
					path: '/offer',
					name: 'Offer',
					component: () => import('@/views/sideBar/Offer/index.vue'),
					meta: {
						auth: true,
						breadcrumb: [
							{ title: 'Offres', active: true },
						],
						pageTitle: 'Offres'
					},
				},
				{
					path: '/settings',
					name: 'Settings',
					component: () => import('@/views/afterAuth/Setting.vue'),
					meta: {
						auth: true,
						breadcrumb: [
							{ title: 'Outils de production', active: true },
							{ title: 'Paramètres', active: true },
						],
						pageTitle: 'Paramètres'
					},
				},
				{
					path: '/stats-team',
					name: 'StatsTeam',
					component: () => import('@/views/sideBar/StatsTeam/index.vue'),
					meta: {
						auth: true,
						breadcrumb: [
							{ title: 'Outils de production', active: true },
							{ title: 'Statistique Equipe', active: true },
						],
						pageTitle: 'Statistique Equipe'
					},
				},
        {
          	path: '/projet-contrat',
          	name: 'ProjetContrat',
          	component: () => import('@/views/sideBar/ProjetContrat/index.vue'),
          	meta: {
          		auth: true,
          		breadcrumb: [
          			{ title: 'Projets & Contrats', active: true },
          		],
          		pageTitle: 'Projets & Contrats'
          	},
          },

				{
					path: '/profil',
					name: 'Profil',
					component: () => import('@/views/afterAuth/Profil.vue'),
					meta: {
						auth: true,
						breadcrumb: [
							{ title: 'Accueil', name: 'Home' },
							{ title: 'Mon Profil', active: true },
						],
						pageTitle: 'Mon Profil'
					},
				},
				{
					path: '/slips',
					name: 'Slips',
					component: () => import('@/views/sideBar/Bordereaux/index.vue'),
					meta: {
						auth: true,
						breadcrumb: [
							{ title: 'Bordereaux de commission', active: true },
						],
						pageTitle: 'Bordereaux de commission'
					},
				}
			],
		},
		// =============================================================================
		// FULL PAGE LAYOUTS
		// =============================================================================
		{
			path: '',
			component: () => import('@/layouts/full-page/FullPage.vue'),
			children: [
				// =============================================================================
				// PAGES
				// =============================================================================
				{
					path: '/login',
					name: 'Login',
					component: () => import('@/views/beforeAuth/Login.vue'),
				},

				{
					path: '/forget-password',
					name: 'ForgetPassword',
					component: () => import('@/views/beforeAuth/ForgetPassword.vue'),
				},
				{
					path: '/error-404',
					name: 'Error404',
					component: () => import('@/views/beforeAuth/Error404.vue'),
				},
				{
					path: '/privacy-and-security',
					name: 'PrivacyAndSecurity',
					component: () => import('@/views/static/PrivacyAndSecurity.vue'),
				}
			]
		},
		// Redirect to 404 page, if no match found
		{
			path: '*',
			redirect: '/error-404'
		}
	],
})


router.beforeEach(authGuard)
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = "none";
	}
})
Vue.router = router

export default router
