import { FETCH_PROSPECT_CLIENT, GET_PROSPECT_CLIENT } from "./mutation-types";

import service from "@/service";
import config from "@/config";
import axios from "axios";

const $service = service(axios, config);

const fakeData = {
  total: 3,
  list: [
    {
      id: 1,
      civility: "M",
      lastname: "Dascosta",
      firstname: "emili",
      beginDate: "1593529842873",
      identity: "Raoul Fofana",
      birthday: "1593529842873",
      phone: "65202256",
      email: "KOTCHOFA@gmail.com",
      projectCounter: 4,
      contratCounter: 5
    },
    {
      id: 2,
      civility: "M",
      lastname: "Dascosta",
      firstname: "emili",
      beginDate: "1593529842873",
      identity: "lili fifina",
      birthday: "1593529842873",
      phone: "65202256",
      email: "KOTCHOFA@gmail.com",
      projectCounter: 4,
      contratCounter: 5
    },
    {
      id: 3,
      civility: "M",
      lastname: "Dascosta",
      firstname: "emili",
      beginDate: "1593529842873",
      identity: "tracy mamama",
      birthday: "1593529842873",
      phone: "65202256",
      email: "KOTCHOFA@gmail.com",
      projectCounter: 4,
      contratCounter: 5
    }
  ]
};
export default {
  async applyGetAllProspectClient({ commit }, data) {
    // const prospectClient = await $service.getAllProspectClient(data);
    // commit(FETCH_PROSPECT_CLIENT, prospectClient.data);
    commit(FETCH_PROSPECT_CLIENT, fakeData);
  },
  async applyGetProspectClient({ commit }, data) {
    // const prospectClient = await $service.getProspectClient(data);
    // commit(GET_PROSPECT_CLIENT, prospectClient.data);
    commit(GET_PROSPECT_CLIENT, fakeData.list[data.clientId - 1]);
  }
};
