import {
  ADD_PROJECT,
  UPDATE_PROJECT,
  REMOVE_PROJECT,
  FETCH_PROJECT,
  GET_PROJECT,
  ENABLE_PROJECT,
  ADD_PROJECT_AVENANT,
  UPDATE_PROJECT_AVENANT,
  REMOVE_PROJECT_AVENANT,
  ADD_PROJECT_Quittance,
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)

export default {
  async applyPutComment({ commit }, data) {
    const project = await $service.putCommentProject(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applyAddComment({ commit }, data) {
    const project = await $service.addCommentProject(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applyRemoveComment({ commit }, data) {
    let { ids } = data;
    const project = await $service.removeComment({
      commentId: ids[0]
    });
    commit(UPDATE_PROJECT, project.data);
  },
  async applyChangeIntermediary({ commit }, data) {
    const project = await $service.putProject(data)
    commit(UPDATE_PROJECT, project.data)
  },
  async applyAddSuscribeOffLine({ commit }, data) {
    const item = await $service.addSuscribeOffLine(data)
    commit(ADD_PROJECT, item.data)
  },
  async applyGetAllProject({ commit }, data) {
    console.log(data,'QUERY_PARAMETERS')
    //delete data.broker
    commit(FETCH_PROJECT,
    {
      list: [],
      total: 0
    })
    const projects = await $service.getAllProject(data)
    commit(FETCH_PROJECT, projects.data)
  },
  async applyGetOngoingProject({ commit }, data) {
    const project = await $service.getProject(data);
    commit(GET_PROJECT, project.data);
  },
  async applyPutOngoingProject({ commit }, data) {
    const project = await $service.putProject(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applyEnableProject({ commit }, data) {
    const project = await $service.putProject(data);
    commit(ENABLE_PROJECT, project.data);
  },
  async applyAddAvenant({ commit }, data) {
    const project = await $service.addAvenant(data);
    commit(ADD_PROJECT_AVENANT, project.data);
  },
  async applyPutAvenant({ commit }, data) {
    const project = await $service.putAvenant(data);
    commit(ADD_PROJECT_AVENANT, project.data);
  },
  async applyRemoveAvenant({ commit }, data) {
    let promises = [];
    let { ids } = data;
    ids.forEach(function (id, index) {
      promises.push(
        (async function (id) {
          await $service.removeAvenant({
            avenantId: id
          });
          commit(REMOVE_PROJECT_AVENANT, id);
        })(id)
      );
    });
    await Promise.all(promises);
  },
  async applyRemoveOngoingProject({ commit }, data) {
    let promises = [];
    let { ids } = data;
    ids.forEach(function (id, index) {
      promises.push(
        (async function (id) {
          await $service.removeProject({
            projectId: id
          });
          commit(REMOVE_PROJECT, id);
        })(id)
      );
    });
    await Promise.all(promises);
  },
  async applyChangeProjectState({ commit }, data) {
    const project = await $service.changeProjectState(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applySentProject({ commit }, data) {
    return await $service.sentProjectMail(data)
  },
  async applyGetProject({ commit }, data) {
    const project = await $service.getProject(data);
    commit(GET_PROJECT, project.data);
  },
  async applyPutQuittance({ commit }, data) {
    const project = await $service.putQuittance(data);
    commit(ADD_PROJECT_Quittance, project.data);
    return project.data.quittances
  },
  async applyChangeQuittanceState({ commit }, data) {
    const project = await $service.changeQuittanceState(data);
    commit(UPDATE_PROJECT, project.data);
  },
}
