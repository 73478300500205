import {
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)
 
export default {
  async applyParrainStats ({ commit }, data) {
    let stats = await $service.parrainStats(data);
    return stats.data;
  },

  async applyMemberStats ({ commit }, data) {
    let stats = await $service.parrainStats(data);
    return stats.data;
  },

  async applyUploadFile ({commit}, data) {
    return await $service.uploadFile(data)
  },
  async applyGetSetting ({commit}, data) {
    return await $service.getSetting(data)
  },
  async applyAddSetting ({commit}, data) {
    return await $service.addSetting(data)
  },
  async applyPutSetting ({commit}, data) {
    return await $service.putSetting(data)
  },
  async applyRemoveSetting ({commit}, data) {
    return await $service.removeSetting(data)
  },
  async updateSocietySetting({commit},data){
    return await $service.putSocietySetting(data)
  },
  async applyGenerateCertificat ({commit}, data) {
    return await $service.generateCertificat(data)
  },
  async applyGenerateCotisation ({commit}, data) {
    return await $service.generateCotisation(data)
  },
  async applyGenerateCotisationSolde ({commit}, data) {
    return await $service.generateCotisationSolde(data)
  },
  async applyGetItemDocument({ commit }, data) {
    return await $service.getItemDocument(data)
  },
}
